import React, { useContext, useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal.component';
import './EditNoteModal.styles.css';
import { NotesContext } from 'providers/NotesProvider';
import Button from 'components/Button/Button.component';
import Input from 'components/Input/Input.component';

const EditNoteModal = (props) => {
  const { updateNoteTitle, getNote } = useContext(NotesContext);
  const { id } = props;

  const [title, setTitle] = useState('');

  useEffect(() => {
    const getNoteById = async () => {
      try {
        const note = await getNote(id);
        setTitle(note.title);
      } catch (error) {
        console.log(error);
      }
    };

    getNoteById();
  }, [id, getNote]);

  function handleClick() {
    updateNoteTitle(id, title);
    props.hide();
  }

  function changeHandler(event) {
    setTitle(event.target.value);
  }

  return (
    <Modal {...props}>
      <div className='edit-note-modal'>
        <label>Title</label>
        <Input type='text' value={title} onChange={changeHandler} autoFocus />

        <div className='edit-note-modal-buttons'>
          <Button onClick={handleClick}>Update</Button>
          <Button onClick={() => props.hide()}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditNoteModal;
