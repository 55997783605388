import React from 'react';
import { HeaderStyled, LoaderStyled } from './Header.styles';
import { NotesContext } from 'providers/NotesProvider';
import Loader from 'assets/loaders/loader.svg';

const Header = ({ title }) => {
  const { isNoteTextUpdating } = React.useContext(NotesContext);
  return (
    <HeaderStyled>
      <span>{title}</span>
      {isNoteTextUpdating && <LoaderStyled src={Loader} alt='...loading' />}
    </HeaderStyled>
  );
};

export default Header;
