import React from 'react';
import './Modal.styles.css';

const Modal = ({ title, hide, children }) => {
  return (
    <div className='modal-mask'>
      <div className='modal-wrapper'>
        <div className='modal'>
          <div className='modal-header'>
            <span>{title}</span>
            <span className='icon-delete' onClick={() => hide()} />
          </div>

          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
