import React, { useState, useEffect } from 'react';
import ApiQuotes from 'services/ApiQuotes';

import './Quote.styles.css';

const Quote = () => {
  const [quote, setQuote] = useState('');
  const [author, setAuthor] = useState('');

  useEffect(() => {
    async function getQuote() {
      try {
        const { data } = await ApiQuotes.getQuote();
        const { author, quote } = data;
        setQuote(quote);
        setAuthor(author);
      } catch (error) {
        setQuote('The begining is the most important part of the work. Plato');
      }
    }
    getQuote();
  }, []);

  return (
    <div className='quote'>
      <div>{quote}</div>
      <div>{author}</div>
    </div>
  );
};

export default Quote;
