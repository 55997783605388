import Button from 'components/Button/Button.component';
import Input from 'components/Input/Input.component';
import React from 'react';
import { Header } from 'styles/common.styles';
import { Form } from './ChangePassword.styles';
import joi from 'joi';
import { useToasts } from 'react-toast-notifications';
import AuthApi from 'services/AuthApi';
import { AuthContext } from 'providers/AuthProvider';

const ChangePassword = () => {
  const { addToast } = useToasts();
  const { getUserName } = React.useContext(AuthContext);

  async function handleSubmit(event) {
    event.preventDefault();

    const { current_password, new_password, repeat_password } = event.target;

    try {
      const values = {
        currentPassword: current_password.value,
        newPassword: new_password.value,
        repeatPassword: repeat_password.value,
      };

      await validateForm(values);

      values.username = getUserName();
      await AuthApi.changePassword(values);
      addToast('Password changed', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    }
  }

  async function validateForm(values) {
    const schema = joi.object({
      currentPassword: joi.string().required(),
      newPassword: joi.string().required(),
      repeatPassword: joi.string().equal(joi.ref('newPassword')),
    });

    try {
      await schema.validateAsync(values, { abortEarly: false });
      return true;
    } catch (error) {
      throw error;
    }
  }

  return (
    <>
      <Header>Change Password</Header>

      <Form onSubmit={handleSubmit}>
        <Input
          type='password'
          placeholder='Current Password'
          name='current_password'
        />
        <Input type='password' placeholder='New Password' name='new_password' />
        <Input
          type='password'
          placeholder='Repeat New Password'
          name='repeat_password'
        />
        <Button type='submit'>Change</Button>
      </Form>
    </>
  );
};

export default ChangePassword;
