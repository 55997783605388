import ApiClient from './ApiClient';

export default {
  getNotesTitles(params) {
    return ApiClient.get('/notes/get-notes-titles', { params });
  },
  addNote(payload) {
    return ApiClient.post('/notes/add-note', payload);
  },
  deleteNote(payload) {
    return ApiClient.delete('/notes/delete-note', { data: payload });
  },
  updateNoteTitle(payload) {
    return ApiClient.put('/notes/update-note-title', payload);
  },
  updateNoteText(payload) {
    return ApiClient.put('/notes/update-note-text', payload);
  },
  getNoteById(params) {
    return ApiClient.get('/notes/get-note', { params });
  },
  getNoteFull(params) {
    return ApiClient.get('/notes/get-note-full', { params });
  },
  updateNotesOrder(payload) {
    return ApiClient.put('/notes/update-order', payload);
  }
};
