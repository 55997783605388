import React from 'react';
import LeftBar from 'components/LeftBar/LeftBar.component';
import NoteContent from 'components/NoteContent/NoteContent.component';

const Content = () => {
  return (
    <>
      <LeftBar />
      <NoteContent />
    </>
  );
};

export default Content;
