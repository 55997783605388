import React from 'react';
import './App.css';
import AuthProvider from 'providers/AuthProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'router/routes';
import { ToastProvider } from 'react-toast-notifications';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ToastProvider>
          <div className='App'>
            <Routes />
          </div>
        </ToastProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
