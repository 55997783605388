import React, { useContext } from 'react';
import './NoteItem.styles.css';

import { NotesContext } from 'providers/NotesProvider';

const NoteItem = ({ note, displayEditModal }) => {
  const { id, title } = note;
  const { setNote, currentNote, deleteNote } = useContext(NotesContext);

  return (
    <div
      className={`note-item ${currentNote.id === id ? 'active-note' : ''}`}
      data-id={id}
    >
      <span className='icon-up-down-triangle my-handle' />
      <span className='note-item-title' onClick={() => setNote(id)}>
        {title}
      </span>
      <span className='icon-pencil' onClick={() => displayEditModal(id)} />
      <span className='icon-delete' onClick={() => deleteNote(id)} />
    </div>
  );
};

export default NoteItem;
