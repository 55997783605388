import React, { useState, useContext } from 'react';
import './LeftBar.styles.css';
import strings from './strings';
import IconButton from 'components/IconButton/IconButton.component';
import NotesList from 'components/NotesList/NotesList.component';
import AddNoteModal from 'components/AddNoteModal/AddNoteModal';
import EditNoteModal from 'components/EditNoteModal/EditNoteModal';
import { NotesContext } from 'providers/NotesProvider';

const LeftBar = () => {
  const [isDisplayAddModal, setIsDisplayAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const { isLeftBarVisible } = useContext(NotesContext);

  function displayAddModal() {
    setIsDisplayAddModal(true);
  }

  function hideAddModal() {
    setIsDisplayAddModal(false);
  }

  function hideEditModal() {
    setIsEditModal(false);
  }

  function displayEditModal(id) {
    setIsEditModal(true);
    setEditId(id);
  }

  return (
    isLeftBarVisible && (
      <div className='left-bar'>
        <IconButton icon={'icon-add'} onClick={() => displayAddModal()} />

        <NotesList displayEditModal={displayEditModal} />
        {isDisplayAddModal && (
          <AddNoteModal title='Add note' hide={() => hideAddModal()} />
        )}

        {isEditModal && (
          <EditNoteModal
            title={strings.editTitle}
            hide={() => hideEditModal()}
            id={editId}
          />
        )}
      </div>
    )
  );
};

export default LeftBar;
