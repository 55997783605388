import React, { useContext } from 'react';
import Header from 'components/Header/Header.component';
import Text from 'components/Text/Text.component';
import { NotesContext } from 'providers/NotesProvider';

import './NoteContent.styles.css';

const NoteContent = () => {
  const { currentNote } = useContext(NotesContext);
  const { note } = currentNote;
  const { title } = note;

  return (
    <div className='note-content'>
      {title && (
        <>
          <Header title={title} />
          <Text />
        </>
      )}
    </div>
  );
};

export default NoteContent;
