import React from 'react';
import './IconButton.styles.css';

const IconButton = (props) => {
  return (
    <button className='icon-button' {...props}>
      <span className={props.icon} />
    </button>
  );
};

export default IconButton;
