import React, { createContext } from 'react';
import AuthApi from 'services/AuthApi';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  function loginUser(userName, token, userId) {
    localStorage.setItem('userId', userId);
    localStorage.setItem('userName', userName);
    localStorage.setItem('token', token);
  }

  function logOutUser() {
    localStorage.removeItem('userName');
    localStorage.removeItem('token');
  }

  function getUserName() {
    return localStorage.getItem('userName');
  }

  const getUserId = React.useCallback(() => {
    return localStorage.getItem('userId');
  }, []);

  async function checkAuth() {
    try {
      const { data } = await AuthApi.checkAuth();
      const { isAuth } = data;
      return isAuth;
    } catch (error) {
      return false;
    }
  }

  return (
    <AuthContext.Provider
      value={{ loginUser, logOutUser, getUserName, checkAuth, getUserId }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
