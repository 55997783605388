import React, { useContext, useState } from 'react';
import Modal from 'components/Modal/Modal.component';
import './AddNoteModal.styles.css';
import { NotesContext } from 'providers/NotesProvider';
import Button from 'components/Button/Button.component';
import Input from 'components/Input/Input.component';

const AddNoteModal = (props) => {
  const { addNote } = useContext(NotesContext);

  const [title, setTitle] = useState('');

  function handleClick() {
    addNote(title);
    props.hide();
  }

  function changeHandler(event) {
    setTitle(event.target.value);
  }

  return (
    <Modal {...props}>
      <div className='add-note-modal'>
        <label>Title</label>
        <Input type='text' value={title} onChange={changeHandler} autoFocus />

        <div className='add-note-modal-buttons'>
          <Button onClick={handleClick}>Add</Button>
          <Button onClick={() => props.hide()}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNoteModal;
