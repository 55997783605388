import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'providers/AuthProvider';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { checkAuth } = useContext(AuthContext);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    async function checkIsAuth() {
      try {
        const isAuth = await checkAuth();
        setAuth({ isAuth });
      } catch (error) {
        setAuth(false);
      }
    }

    checkIsAuth();
  }, [checkAuth]);

  return (
    auth && (
      <Route
        {...rest}
        render={(props) => {
          return auth.isAuth ? <Component {...props} /> : <Redirect to='/' />;
        }}
      />
    )
  );
};

export default ProtectedRoute;
