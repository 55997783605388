import styled from 'styled-components';

export const ContentStyled = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;

  ${({ isLeftBarVisible }) =>
    !isLeftBarVisible && `
    grid-template-columns: 1fr;
  `}

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
  }
`;
