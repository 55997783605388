import React from 'react';
import { ContentStyled } from './Dashboard.styles';
import TopBar from 'components/TopBar/TopBar.component';
import Footer from 'components/Footer/Footer.component';
import { Switch } from 'react-router-dom';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute.component';
import Settings from 'pages/Settings/Settings.component';
import Content from 'pages/Content/Content.component';
import { NotesContext } from 'providers/NotesProvider';

const Dashboard = () => {
  const { isLeftBarVisible } = React.useContext(NotesContext);

  return (
    <ContentStyled isLeftBarVisible={isLeftBarVisible}>
      <TopBar />
      <Switch>
        <ProtectedRoute path='/dashboard/settings' component={Settings} />
        <ProtectedRoute path='/dashboard/notes' component={Content} />
      </Switch>
      <Footer />
    </ContentStyled>
  );
};

export default Dashboard;
