import React, { useContext } from 'react';
import { TopBarStyled, IconPower, NavLinkStyled } from './TopBar.styles';
import { Button } from 'styles/common.styles';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'providers/AuthProvider';
import IconButton from 'components/IconButton/IconButton.component';
import { NotesContext } from 'providers/NotesProvider';

const TopBar = () => {
  const history = useHistory();
  const { logOutUser, getUserName } = useContext(AuthContext);
  const { isLeftBarVisible, setIsLeftBarVisible } = useContext(NotesContext);

  function handleLogout() {
    logOutUser();
    history.push('/');
  }

  function toggleBar() {
    setIsLeftBarVisible(!isLeftBarVisible);
  }

  return (
    <TopBarStyled>
      <NavLinkStyled to='/dashboard/notes'>Notes</NavLinkStyled>
      <IconButton icon={'icon-triple-bar'} onClick={() => toggleBar()} />
      <NavLinkStyled to='/dashboard/settings'>
        <Button>{getUserName()}</Button>
      </NavLinkStyled>
      <IconPower className='icon-power' onClick={handleLogout} />
    </TopBarStyled>
  );
};

export default TopBar;
