import ApiClient from './ApiClient';

export default {
    login(credentials) {
        return ApiClient.post('/login', credentials);
    },
    checkAuth() {
        return ApiClient.get('/check-auth');
    },
    changePassword(credentials){
        return ApiClient.post('/change-password', credentials);
    }
}
