import React, { useContext } from 'react';
import { NotesContext } from 'providers/NotesProvider';
import NoteItem from 'components/NoteItem/NoteItem.component';
import { ReactSortable } from 'react-sortablejs';

const NotesList = ({ displayEditModal }) => {
  const { notesTitles, setNotesTitles, updateNotesOrder } = useContext(
    NotesContext
  );

  const handleSortEnd = ({ target: { children } }) => {
    const items = Array.from(children);
    const orderById = items.map((item) => item.getAttribute('data-id'));
    updateNotesOrder(orderById);
  };

  return (
    <ReactSortable
      list={notesTitles}
      setList={setNotesTitles}
      handle={'.my-handle'}
      onEnd={handleSortEnd}
    >
      {notesTitles.map((note) => (
        <NoteItem
          key={note.id}
          note={note}
          displayEditModal={displayEditModal}
        />
      ))}
    </ReactSortable>
  );
};

export default NotesList;
