import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from 'pages/Login/Login.component';
import Dashboard from 'pages/Dashboard/Dashboard.component';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute.component';
import NotesProvider from 'providers/NotesProvider';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Login} />
      <NotesProvider>
        <ProtectedRoute path='/dashboard' component={Dashboard} />
      </NotesProvider>
    </Switch>
  );
};

export default Routes;
