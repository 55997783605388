import styled from 'styled-components';

const HeaderStyled = styled.div`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoaderStyled = styled.img`
  height: 16px;
  margin-left: 0.5rem;
`;

export { HeaderStyled, LoaderStyled };
