import axios from 'axios';
import conf from 'config';

const ApiClient = axios.create({
  baseURL: `${conf.baseUrl}/${conf.baseAPI}`,
  headers: { 'Content-Type': 'application/json' },
});

ApiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default ApiClient;
