import React from 'react';
import { Page, Header } from 'styles/common.styles';
import ChangePassword from 'components/ChangePassword/ChangePassword.component';

const Settings = () => {
  return (
    <Page>
      <Header>Settings</Header>
      <ChangePassword />
    </Page>
  );
};

export default Settings;
