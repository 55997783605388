import styled from 'styled-components';

const Button = styled.button`
  cursor: pointer;
  font-size: 1.2rem;
  background-color: green;
  color: white;
  border-radius: 1rem;
  display: inline-block;
  text-decoration: none;
  border: 1px solid green;
  outline: 0;
  padding: 0 1rem;
`;

const Page = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  padding: 0.3rem 0;
  border-bottom: 1px solid rgba(64, 64, 64, 0.5);
`;

export { Button, Page, Header };
