import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button/Button.component';
import Input from 'components/Input/Input.component';
import AuthApi from 'services/AuthApi';

import './Login.styles.css';

import { AuthContext } from 'providers/AuthProvider';
import Quote from 'components/Quote/Quote.component';

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { loginUser } = useContext(AuthContext);

  async function handleLogin() {
    try {
      const credentials = {
        email,
        password,
      };

      const results = await AuthApi.login(credentials);
      const { data } = results;
      const { username, token, userId } = data;

      if (token) {
        loginUser(username, token, userId);
        history.push('/dashboard/notes');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  return (
    <div className='login-page'>
      <Quote />
      <div className='login'>
        <div>Notes App</div>

        <div>Email</div>
        <Input type='email' value={email} onChange={handleEmailChange} />

        <div>Password</div>
        <Input
          type='password'
          value={password}
          onChange={handlePasswordChange}
        />

        <div className='login-buttons'>
          <Button onClick={handleLogin}>Login</Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
