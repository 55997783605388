import React, { createContext, useState, useEffect, useCallback } from 'react';
import ApiNotes from 'services/ApiNotes';
import { useContext } from 'react';
import { AuthContext } from './AuthProvider';
import { debounce } from 'utils';

export const NotesContext = createContext();

const initialNote = {
  id: null,
  note: { title: '', data: [] },
};

let timeout = null;

const NotesProvider = ({ children }) => {
  const [notesTitles, setNotesTitles] = useState([]);
  const [currentNote, setCurrentNote] = useState(initialNote);
  const { getUserId } = useContext(AuthContext);
  const [isNoteTextUpdating, setIsNoteTextUpdating] = useState(false);
  const [isLeftBarVisible, setIsLeftBarVisible] = useState(true);

  const debounceApi = useCallback(
    debounce(async (payload) => {
      await ApiNotes.updateNoteText(payload);
      setIsNoteTextUpdating(false);
    }, timeout),
    []
  );

  useEffect(() => {
    async function fetchNotesTitles() {
      try {
        const notesTitles = await getNotesTitles(getUserId());
        setNotesTitles(notesTitles);
      } catch (error) {
        console.log(error);
        return [];
      }
    }

    fetchNotesTitles();
  }, [getUserId]);

  async function setNote(id) {
    try {
      const note = await getNoteFull(id);
      setCurrentNote(note);
    } catch (error) {
      console.log(error);
    }
  }

  async function getNote(id) {
    try {
      const { data } = await ApiNotes.getNoteById({
        id,
        userId: getUserId(),
      });

      return data.note;
    } catch (error) {
      console.log(error);
    }
  }

  async function getNoteFull(id) {
    try {
      const { data } = await ApiNotes.getNoteFull({
        id,
        userId: getUserId(),
      });

      return data.note;
    } catch (error) {
      console.log(error);
    }
  }

  async function getNotesTitles(userId) {
    try {
      const { data } = await ApiNotes.getNotesTitles({ userId });
      const { notes } = data;
      return notes;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async function addNote(title) {
    if (!title) return;

    const note = {
      title,
      data: [],
    };

    const userId = getUserId();

    const payload = {
      note,
      userId,
    };

    try {
      const { data } = await ApiNotes.addNote(payload);
      const { id } = data;
      const addedNote = await getNote(id);
      addNoteUpdate(addedNote);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateNoteText(data) {
    try {
      const note = { ...currentNote, note: { ...currentNote.note, data } };
      setIsNoteTextUpdating(true);
      setCurrentNote(note);

      const payload = {
        id: currentNote.id,
        userId: getUserId(),
        data,
      };

      debounceApi(payload);
    } catch (error) {
      setIsNoteTextUpdating(false);
      console.log(error);
    }
  }

  function updateNotes(note) {
    const notesCopy = [...notesTitles];
    const index = notesTitles.findIndex((item) => note.id === item.id);
    notesCopy.splice(index, 1, note);
    setNotesTitles(notesCopy);
  }

  function deleteNoteUpdate(note) {
    const notesCopy = [...notesTitles];
    const index = notesTitles.findIndex((item) => note.id === item.id);
    notesCopy.splice(index, 1);
    setNotesTitles(notesCopy);
  }

  function addNoteUpdate(note) {
    const notesCopy = [...notesTitles];
    notesCopy.push(note);
    setNotesTitles(notesCopy);
  }

  async function updateNoteTitle(id, title) {
    try {
      const payload = {
        id,
        userId: getUserId(),
        title,
      };

      await ApiNotes.updateNoteTitle(payload);

      const note = await getNote(id);
      updateNotes(note);

      if (id === currentNote.id) {
        const currentNote = await getNoteFull(id);
        setCurrentNote(currentNote);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteNote(id) {
    if (id === currentNote.id) {
      setCurrentNote(initialNote);
    }

    const note = notesTitles.find((item) => item.id === id);
    const userId = getUserId();

    try {
      const payload = {
        id,
        userId,
      };

      await ApiNotes.deleteNote(payload);
      deleteNoteUpdate(note);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateNotesOrder(order) {
    try {
      const payload = {
        userId: getUserId(),
        order
      }

      await ApiNotes.updateNotesOrder(payload);
      
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <NotesContext.Provider
      value={{
        notesTitles,
        currentNote,
        setNote,
        setNotesTitles,
        getNotesTitles,
        updateNoteText,
        isNoteTextUpdating,
        deleteNote,
        addNote,
        updateNoteTitle,
        getNote,
        isLeftBarVisible,
        setIsLeftBarVisible,
        updateNotesOrder,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export default NotesProvider;
