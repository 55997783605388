import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const TopBarStyled = styled.div`
  grid-row: 1;
  grid-column: 1 / span 2;
  padding: 0.5rem;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto auto auto;
  gap: 1rem;
  align-items: center;

  border-bottom: 1px solid rgba(64, 64, 64, 0.5);
`;

const NavLinkStyled = styled(NavLink)`
  color: white;
  text-decoration: none;
`;

const IconPower = styled.i`
  color: green;
  cursor: pointer;
`;

export { TopBarStyled, IconPower, NavLinkStyled };
