import React, { useContext, useState, useEffect } from 'react';
import './Text.styles.css';
import { NotesContext } from 'providers/NotesProvider';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Text = () => {
  const { updateNoteText, currentNote } = useContext(NotesContext);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!currentNote.note.data.length)
      return setEditorState(EditorState.createEmpty());

    const data = convertFromRaw(JSON.parse(currentNote.note.data));
    setEditorState(EditorState.createWithContent(data));
    // eslint-disable-next-line
  }, [currentNote.id]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    updateNoteText(
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );
  };

  return (
    <Editor
      editorState={editorState}
      toolbarClassName='toolbarClassName'
      wrapperClassName='wrapperClassName'
      editorClassName='editorClassName'
      editorStyle={{ height: 'auto' }}
      onEditorStateChange={onEditorStateChange}
      toolbarStyle={{ color: 'black' }}
    />
  );
};

export default Text;
